import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../../../config';
import { AuthenticationService } from '../../shared/authentication/authentication.service';
import { ErrorReporterService } from 'src/app/shared/error-reporter/error-reporter.service';
import { IUser } from 'src/app/shared/users-resource/user.interface';

@Component({
  selector: 'app-toolbar',
  template: `<mat-toolbar color="primary">
    <button mat-button class="menu" [matMenuTriggerFor]="mainMenu">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="title"
      ><a routerLink="/">{{ title }}</a></span
    >
    <button
      mat-button
      class="toolbar-button"
      [routerLink]="['/presubscriptions']"
      *ngIf="isAuthenticated | async"
    >
      <mat-icon>receipt_long</mat-icon>
      Mes demandes
    </button>

    <span class="fill-remaining-space"></span>

    <button
      mat-button
      class="toolbar-button"
      [routerLink]="['/admin']"
      *ngIf="canReadAdmin | async"
    >
      <mat-icon>shield_person</mat-icon>
      Admin
    </button>
    <button
      mat-button
      *ngIf="isAnonymous | async"
      [routerLink]="['/user', 'login']"
      class="toolbar-button"
      (click)="onSignInClick()"
    >
      <mat-icon>login</mat-icon>
      Connexion
    </button>

    <button
      mat-button
      *ngIf="isAuthenticated | async"
      [matMenuTriggerFor]="userMenu"
      class="toolbar-button"
    >
      <mat-icon>person</mat-icon>
      {{ (user | async)!.name }}
      <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
    </button>

    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="onSignOutClick()">
        <mat-icon>logout</mat-icon>
        Déconnexion
      </button>
    </mat-menu>

    <mat-menu #mainMenu="matMenu">
      <button
        mat-menu-item
        [routerLink]="['/presubscriptions']"
        *ngIf="isAuthenticated | async"
      >
        <mat-icon>receipt_long</mat-icon>
        Mes demandes
      </button>

      <mat-divider *ngIf="canReadAdmin | async"></mat-divider>

      <button
        mat-menu-item
        *ngIf="canReadAdmin | async"
        [routerLink]="['admin', 'seasons']"
      >
        <mat-icon>date_range</mat-icon>
        <span>Saisons</span>
      </button>

      <button
        mat-menu-item
        *ngIf="canReadAdmin | async"
        [routerLink]="['admin', 'courses']"
      >
        <mat-icon>event</mat-icon>
        <span>Cours</span>
      </button>

      <button
        mat-menu-item
        *ngIf="canReadAdmin | async"
        [routerLink]="['admin', 'repartition']"
      >
        <mat-icon>group_add</mat-icon>
        <span>Répartition</span>
      </button>

      <button
        mat-menu-item
        *ngIf="canReadAdmin | async"
        [routerLink]="['admin', 'presubscriptions']"
      >
        <mat-icon>group</mat-icon>
        <span>Pré-inscriptions</span>
      </button>

      <button
        mat-menu-item
        *ngIf="canReadAdmin | async"
        [routerLink]="['admin', 'check']"
      >
        <mat-icon>fact_check</mat-icon>
        <span>Pointage</span>
      </button>

      <button
        mat-menu-item
        *ngIf="canReadAdmin | async"
        [routerLink]="['admin', 'stats']"
      >
        <mat-icon>query_stats</mat-icon>
        <span>Statistiques</span>
      </button>

      <button
        *ngIf="isAdmin | async"
        mat-menu-item
        [routerLink]="['admin', 'users']"
      >
        <mat-icon>manage_accounts</mat-icon>
        <span>Utilisateurs</span>
      </button>

      <button
        *ngIf="isAdmin | async"
        mat-menu-item
        [routerLink]="['admin', 'processings']"
      >
        <mat-icon>engineering</mat-icon>
        <span>Traitements</span>
      </button>

      <mat-divider *ngIf="isAuthenticated | async"></mat-divider>

      <button
        mat-menu-item
        *ngIf="isAnonymous | async"
        [routerLink]="['/user', 'login']"
        (click)="onSignInClick()"
      >
        Connexion
      </button>

      <button
        mat-menu-item
        *ngIf="isAuthenticated | async"
        (click)="onSignOutClick()"
      >
        Déconnexion
      </button>
    </mat-menu>
  </mat-toolbar>`,
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  public title: string = CONFIG.app.name;
  public isAuthenticated: Observable<boolean>;
  public isAnonymous: Observable<boolean>;
  public canReadAdmin: Observable<boolean>;
  public isAdmin: Observable<boolean> = this.authenticationService.isAdmin();
  public user: Observable<IUser | null>;

  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly errorReporter: ErrorReporterService,
  ) {
    this.isAuthenticated = this.authenticationService.isAuthenticated();
    this.isAnonymous = this.isAuthenticated.pipe(map((x) => !x));
    this.user = this.authenticationService.getUser();
    this.canReadAdmin = this.authenticationService.canReadAdmin();
  }

  public onSignInClick() {
    this.authenticationService.setUrlAfterLogin();
  }

  public onSignOutClick() {
    this.authenticationService.logout();
    this.router.navigate(['/']).catch((e: Error) => {
      this.errorReporter.handleError(e);
    });
  }
}
