import { Injectable } from '@angular/core';
import { CaptureContext, SeverityLevel } from '@sentry/types';

import { IErrorReporter } from './error-reporter.interface';

@Injectable()
export class LocalReporterService implements IErrorReporter {
  constructor() {}

  public captureException(error: Error): void {
    console.error(error);
  }

  captureMessage(
    message: string,
    captureContext?: CaptureContext | SeverityLevel,
  ) {
    console.log(captureContext, message);
  }
}
