import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';

import { IndexComponent } from './pages/index/index.component';
import { IndexModule } from './pages/index/index.module';
import { SeasonResourceModule } from './shared/season-resource/season-resource.module';
import { SeasonResolver } from './shared/season-resource/season.resolver';

const routes: Routes = [
  {
    path: 'season/:seasonId/create-presubscription',
    loadChildren: () =>
      import(
        './pages/create-presubscription/create-presubscription.module'
      ).then(({ CreatePreSubscriptionModule }) => CreatePreSubscriptionModule),
    resolve: {
      season: SeasonResolver,
    },
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then(
        ({ ContactModule }) => ContactModule,
      ),
  },
  {
    path: 'presubscriptions',
    loadChildren: () =>
      import('./pages/presubscriptions/presubscriptions.module').then(
        ({ PresubscriptionsModule }) => PresubscriptionsModule,
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/user/user.module').then(({ UserModule }) => UserModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./pages/admin/admin.module').then(
        ({ AdminModule }) => AdminModule,
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    component: IndexComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    IndexModule,
    SeasonResourceModule,
  ],
  exports: [RouterModule],
  providers: [provideRouter(routes, withComponentInputBinding())],
})
export class AppRoutingModule {}
