import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { SeasonResourceService } from './season-resource.service';
import { ISeason } from './season.interface';

@Injectable({ providedIn: 'root' })
export class SeasonResolver {
  public constructor(private readonly seasonResource: SeasonResourceService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ISeason | null> {
    const id = route.paramMap.get('seasonId');
    return id ? this.seasonResource.get(+id) : of(null);
  }
}
