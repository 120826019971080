import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { CanReadAdminGuard } from './can-read-admin.guard';
import { IsAdminGuard } from './is-admin.guard';
import { IsAuthenticatedGuard } from './is-authenticated.guard';
import { IsNotAuthenticatedGuard } from './is-not-authenticated.guard';

@NgModule({
  imports: [RouterModule],
  exports: [],
  declarations: [],
  providers: [
    AuthenticationService,
    IsAuthenticatedGuard,
    IsNotAuthenticatedGuard,
    IsAdminGuard,
    CanReadAdminGuard,
  ],
})
export class AuthenticationModule {
  constructor(@Optional() @SkipSelf() parentModule?: AuthenticationModule) {
    if (parentModule) {
      throw new Error(
        'AuthenticationModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
