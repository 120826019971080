import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ErrorReporterService } from './error-reporter.service';
import { LocalReporterService } from './local-reporter.service';

@NgModule({
  imports: [MatSnackBarModule],
  exports: [],
  declarations: [],
  providers: [ErrorReporterService, LocalReporterService],
})
export class ErrorReporterModule {}
