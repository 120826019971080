import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import { IndexComponent } from './index.component';
import { NotificationModule } from 'src/app/shared/component/notification/notification.module';
import { SeasonResourceModule } from 'src/app/shared/season-resource/season-resource.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    SeasonResourceModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    RouterModule,
    NotificationModule,
  ],
  declarations: [IndexComponent],
  exports: [],
  providers: [],
})
export class IndexModule {}
