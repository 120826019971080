import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class IsAdminGuard {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  public canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }

  public canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isAdmin().pipe(
      tap((isAdmin) => {
        if (!isAdmin) {
          this.authService.setUrlAfterLogin();
        }
      }),
      map((isAdmin) => {
        if (!isAdmin) {
          return this.router.parseUrl('/user/login');
        }

        return true;
      }),
    );
  }
}
