import { Injectable } from '@angular/core';

import { CONFIG } from 'src/config';

@Injectable()
export class ApiResourceService {
  constructor() {}

  public getApiUrl(path: string): string {
    return `${CONFIG.api.url}${path}`;
  }

  public getAuthorizationHeader(
    token: string | null | undefined,
  ): { [key: string]: string } | undefined {
    if (!token) {
      return undefined;
    }

    return {
      Authorization: `Bearer ${token}`,
    };
  }
}
