import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, share, tap } from 'rxjs/operators';

import {
  ICredentials,
  ICredentialsRaw,
  IRefreshCredentialsRaw,
} from './credentials.interface';
import { ApiResourceService } from '../api-resource/api-resource.service';
import { IUser, Role } from '../users-resource/user.interface';

const STORAGE_KEY = 'credentials';
const ABOUT_TO_EXPIRE_MS = 10 * 60 * 1e3;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentCredentials: ReplaySubject<ICredentials | null> =
    new ReplaySubject(1);
  private nextUrl?: string;

  private currentRefreshedCredentials = this.currentCredentials
    .asObservable()
    .pipe(
      mergeMap((credentials) => {
        if (!credentials) {
          return of(null);
        }

        if (isAboutToExpire(credentials)) {
          return this.refresh(credentials);
        }

        return of(credentials);
      }, 1),
      share(),
    );

  constructor(
    private readonly http: HttpClient,
    private readonly apiResourceService: ApiResourceService,
    private readonly router: Router,
    private readonly apiResource: ApiResourceService,
  ) {
    const credentials = loadCredentials();
    if (credentials && isExpired(credentials)) {
      this.refresh(credentials).subscribe();
    } else {
      this.applyNewCredentials(credentials);
    }
  }

  public isAuthenticated(): Observable<boolean> {
    return this.currentCredentials
      .asObservable()
      .pipe(map((credentials: ICredentials | null) => Boolean(credentials)));
  }

  public isAdmin(): Observable<boolean> {
    return this.currentCredentials
      .asObservable()
      .pipe(
        map((credentials: ICredentials | null) =>
          Boolean(credentials && credentials.user.role === Role.Admin),
        ),
      );
  }

  public canReadAdmin(): Observable<boolean> {
    return this.currentCredentials
      .asObservable()
      .pipe(
        map((credentials: ICredentials | null) =>
          Boolean(
            (credentials && credentials.user.role === Role.Admin) ||
              credentials?.user.role === Role.AdminRead,
          ),
        ),
      );
  }

  public getUser(): Observable<IUser | null> {
    return this.currentCredentials
      .asObservable()
      .pipe(map((credentials) => (credentials && credentials.user) || null));
  }

  public getToken(): Observable<string | null> {
    return this.currentRefreshedCredentials.pipe(
      map((credentials) => (credentials && credentials.access_token) || null),
    );
  }

  public login(
    emailAddress: string,
    password: string,
  ): Observable<ICredentials> {
    return this.http
      .post<ICredentialsRaw>(
        this.apiResourceService.getApiUrl('/users/login'),
        {
          email_address: emailAddress,
          password,
        },
      )
      .pipe(
        map(mapCredentials),
        tap((credentials) => {
          this.applyNewCredentials(credentials);
        }),
      );
  }

  public refresh(
    currentCredentials: ICredentials,
  ): Observable<ICredentials | null> {
    return this.http
      .post<IRefreshCredentialsRaw>(
        this.apiResource.getApiUrl(`/users/refresh`),
        undefined,
        {
          headers: this.apiResourceService.getAuthorizationHeader(
            currentCredentials.access_token,
          ),
        },
      )
      .pipe(
        map(mapCredentials),
        map((refreshCredentials) => {
          const credentials = {
            user: currentCredentials.user,
            access_token: refreshCredentials.access_token,
            expirationTimestamp: refreshCredentials.expirationTimestamp,
            expires_in: refreshCredentials.expires_in,
          };
          return credentials;
        }),
        tap((credentials) => {
          this.applyNewCredentials(credentials);
        }),
        catchError((error: HttpErrorResponse) => {
          if (!error.status) {
            console.warn('Connection error');
            return throwError(error);
          }

          console.warn('Failed to refresh token', error);
          this.applyNewCredentials(null);
          return of(null);
        }),
      );
  }

  public logout(): void {
    this.applyNewCredentials(null);
  }

  public setUrlAfterLogin(): void {
    if (this.router.url !== '/user/login') {
      this.nextUrl = this.router.url;
    } else if (!this.nextUrl) {
      this.nextUrl = '/';
    }
  }

  public getNextUrlAfterLogin(): string {
    return this.nextUrl!;
  }

  private applyNewCredentials(credentials: ICredentials | null): void {
    if (!credentials) {
      localStorage.removeItem(STORAGE_KEY);
      this.currentCredentials.next(null);
    } else {
      this.currentCredentials.next(credentials);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(credentials));
    }
  }
}

function mapCredentials<T extends { expires_in: number }>(
  credentials: T,
): T & { expirationTimestamp: number } {
  return {
    ...credentials,
    expirationTimestamp: Date.now() + credentials.expires_in * 1e3,
  };
}

function loadCredentials(): ICredentials | null {
  const loaded = localStorage.getItem(STORAGE_KEY);

  if (!loaded) {
    return null;
  }

  const credentials = JSON.parse(loaded) as ICredentials;

  return credentials;
}

function isExpired(credentials: ICredentials | null): boolean {
  return !credentials || credentials.expirationTimestamp <= Date.now();
}

function isAboutToExpire(credentials: ICredentials | null): boolean {
  return (
    !credentials ||
    credentials.expirationTimestamp - ABOUT_TO_EXPIRE_MS <= Date.now()
  );
}
