import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CaptureContext, Severity } from '@sentry/types';

import { IErrorReporter } from './error-reporter.interface';
import { LocalReporterService } from './local-reporter.service';
import { CONFIG } from 'src/config';

interface Sentry extends IErrorReporter {
  init: (config: { dsn: string }) => void;
}

@Injectable()
export class ErrorReporterService implements ErrorHandler {
  private reporter: Promise<IErrorReporter>;

  constructor(
    localReporter: LocalReporterService,
    private snackBar: MatSnackBar,
  ) {
    if (CONFIG.app.isProduction && CONFIG.sentry.dsn !== null) {
      const sentry = import('@sentry/browser');

      this.reporter = sentry.then((loaded: Sentry) => {
        if (CONFIG.sentry.dsn) {
          loaded.init({
            dsn: CONFIG.sentry.dsn,
          });

          return loaded;
        }

        return localReporter;
      });
    } else {
      this.reporter = Promise.resolve(localReporter);
    }
  }

  public handleError(error: Error | object, errorMessage?: string): void {
    console.error(error);

    this.snackBar.open(errorMessage || 'Une erreur est survenue', 'Fermer', {
      duration: 2000,
      announcementMessage: errorMessage || 'Une erreur est survenue',
      politeness: 'assertive',
    });

    this.reporter
      .then((reporter: IErrorReporter) => {
        if (error instanceof Error) {
          reporter.captureException(error);
        } else {
          reporter.captureMessage('ERROR', {
            level: 'error',
            extra: error as Record<string, unknown>,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public log(message: string, context: CaptureContext | Severity) {
    this.reporter
      .then((reporter: IErrorReporter) => {
        reporter.captureMessage(message, context);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
