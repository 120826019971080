export interface UserCreateRequest {
  name: string;
  email_address: string;
  password: string;
}

export enum Role {
  Admin = 'admin',
  AdminRead = 'read',
  None = 'none',
}

export interface IUser {
  id: string;
  name: string;
  email_address: string;
  role: Role;
}

export interface IUserAdmin extends IUser {
  is_verified: boolean;
}

export interface UserAdminEditAttributes extends Omit<IUserAdmin, 'id'> {}

export interface UserAdminCreateAttributes extends UserAdminEditAttributes {
  password: string;
}
