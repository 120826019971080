export const CONFIG = {
  api: {
    url: 'https://inscriptions-api.al-escalade.fr',
  },
  app: {
    name: 'Inscriptions AL-Escalade',
    contact: 'inscriptions@al-escalade.fr',
    isProduction: true,
  },
  sentry: {
    dsn: 'https://5c69cecb37c34824878c28145bd1b3c5@o399656.ingest.sentry.io/5257123',
  },
};
